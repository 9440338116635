const experienceData = [
    {
      id: 1,
      date: "NOV 2021 — Current",
      position: "Sales Manager",
      company: "Dania 18 California",
      description: "I improved data reporting by implementing strategic upgrades to Power BI and Salesforce, which led to a 35% increase in online revenue. Using advanced Excel techniques to analyze market and sales data, I created detailed reports that improved data accuracy and supported informed decision-making. I also developed and executed a new sales strategy that resulted in a 25% increase in customer retention."
    },
    {
      id: 2,
      date: "OCT 2013 — DEC 2019",
      position: "Mathematics Teacher",
      company: "Moderna Organização Pedagógica Integrada",
      description: "I used data analysis to assess student performance and inform teaching strategies, which improved the educational outcomes. I also designed a curriculum based on data that significantly raised the level of engagement and understanding of mathematical concepts in students."
    }
];

export default experienceData;