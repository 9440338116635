import { ArrowUp } from './common/ArrowUp.js';
import projectsData from '../data/ProjectsData.js';
import { Glow, GlowCapture } from '@codaworks/react-glow'; // Import Glow components
import './styles/projects.css';

const Project = ({ image, projectName, description, linkAddress, technologies, imageWidth, imageHeight }) => (
  <GlowCapture>
    {/* Apply Glow effect to the project container */}
    <Glow color="#5D7384"> {/* Set the glow color */}
      <div className="project_container">
        <header className="project_header">
          {/* Set explicit width and height on the image */}
          <img 
            src={image} 
            alt={`Screenshot of ${projectName}`} 
            className="project_image" 
            width={imageWidth} 
            height={imageHeight} 
          />
        </header>
        <div className="project_body">
          <h3 className="project_body_title">
            <ArrowUp
              className="py-4 pr-8 text-xs font-semibold text-slate-200"
              to={linkAddress}
              linkText={projectName}
            />
          </h3>
          <p className="project_body_description">{description}</p>
          <ul className="project_tech_container">
            {technologies.map((tech) => (
              <li key={tech} className="project_tech_list">{tech}</li>
            ))}
          </ul>
        </div>
      </div>
    </Glow>
  </GlowCapture>
);

export const Projects = () => {
  return (
    <section id="projects" className="mt-32">
      <h2 className="project_title">Projects</h2>
      {projectsData.map((project) => (
        <Project
          key={project.id} // Ensure each Project has a unique key
          image={project.image}
          projectName={project.projectName}
          description={project.description}
          linkAddress={project.linkAddress}
          technologies={project.technologies}
          imageWidth={400} // You can adjust the width and height here
          imageHeight={300} // You can adjust the width and height here
        />
      ))}
      <div className="md:px-6 md:mt-8">
        <ArrowUp to="/AllProjects" linkText="View Full Project Archive" />
      </div>
    </section>
  );
};
