import { ArrowLeft } from '../components/common/ArrowLeft';
import { ArrowUp } from '../components/common/ArrowUp';
import projectsData from '../data/ProjectsData';
import '../components/styles/AllProjects.css';




const AllProjects = () => {
  return (
    <div className='main_container'>
      <div className='row'>
        <header>
          <ArrowLeft
            to='/'
            linkText='Alessandro Branco'
          />
          <h1 className='title'>All Projects</h1>
        </header>
        <section className='mt-[40px] overflow-hidden'>
          <table className="mt-12 w-full border-collapse text-left">
            <thead className='sticky top-0 z-10 border-b border-slate-300/10 bg slate-900/75 px-6 py-5 backdrop-blur'>
              <tr>
                <th className='py-4 pr-8 text-sm font-semibold text-slate-200'>Year</th>
                <th className='py-4 pr-8 text-sm font-semibold text-slate-200'>Project</th>
                <th className='hidden py-4 pr-8 text-sm font-semibold text-slate-200 lg:table-cell'>Made At</th>
                <th className='hidden py-4 pr-8 text-sm font-semibold text-slate-200 lg:table-cell'>Built With</th>
                <th className='hidden py-4 pr-8 text-sm font-semibold text-slate-200 sm:table-cell'>Link</th>
              </tr>
            </thead>
            <tbody>
              {projectsData.map(project => (
                <tr className='border-b border-slate-300/10 last:border-none' key={project.id}>
                  <td className='py-4 pr-4 align-top text-sm text-slate-200'>
                    <div className='translate-y-px'>{project.year}</div>
                  </td>
                  <td className='py-4 pr-4 align-top font-semibold leading-snug text-slate-200'>
                    <div className='block sm:hidden text-xs pr-8 font-semibold text-slate-200'>
                      <ArrowUp
                        to='/AllProjects'
                        linkText={project.projectName}
                      />
                    </div>
                    <div className='hidden sm:block'>{project.projectName}</div>
                  </td>
                  <td className='hidden py-4 pr-4 align-top text-sm text-slate-200 lg:table-cell'>
                    <div className='translate-y-px whitespace-nowrap'>{project.madeAt}</div>
                  </td>
                  <td className='hidden py-4 align-top sm:table-cell'>
                    <ul className='flex -translate-y-1.5 flex-wrap'>
                      {project.technologies.map((tech, index) => (
                         <li key={index} className='text-xs font-semibold text-slate-200 p-2 my-1 mr-1.5 bg-customGreen bg-opacity-10 rounded-full'>
                          {tech}
                        </li>
                      ))}
                    </ul> 
                  </td>
                  <td className='hidden sm:block text-sm'>
                    <ArrowUp
                      className='py-4 pr-8 text-xs font-semibold text-slate-200'
                      to='/AllProjects'
                      linkText='Link to the project'
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </div>
    </div>
  );
};

export default AllProjects;