export const Footer = () => {
  return (
    <footer>
        <div className='w-full mt-[128px] md:w-2/3'>
            <p className="font-sans text-sm capitalize text-white text-opacity-60 pb-[17px]">
            Design inspired by Brittany Chiang's portfolio, crafted and coded by me in <span className='bold text-white text-opacity-100'>Visual Studio Code</span>. Built with <span className='bold text-white text-opacity-100'>React.js</span> and <span className='bold text-white text-opacity-100'>Tailwind CSS</span>, deployed on <span className='bold text-white text-opacity-100'>Netlify</span>
            </p>
        </div>
    </footer>
  );
};